import React from "react"

export const CalendarSVG = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.59781 15.2063H13.7622C14.9326 15.2063 15.8837 14.3146 15.8837 13.2174V3.30628C15.8837 2.20906 14.9326 1.31739 13.7622 1.31739H12.9208V0.761844C12.9208 0.456285 12.6541 0.206299 12.3281 0.206299C12.0022 0.206299 11.7356 0.456285 11.7356 0.761844V1.31739H4.62449V0.761844C4.62449 0.456285 4.35779 0.206299 4.03186 0.206299C3.70593 0.206299 3.43928 0.456285 3.43928 0.761844V1.31739H2.59781C1.42744 1.31739 0.476318 2.20906 0.476318 3.30628V13.2174C0.476318 14.3146 1.42744 15.2063 2.59781 15.2063ZM1.66153 3.30628C1.66153 2.82297 2.08227 2.42853 2.59781 2.42853H3.43928V2.98407C3.43928 3.28963 3.70593 3.53962 4.03186 3.53962C4.35779 3.53962 4.62444 3.28963 4.62444 2.98407V2.42853H11.7356V2.98407C11.7356 3.28963 12.0022 3.53962 12.3281 3.53962C12.6541 3.53962 12.9207 3.28963 12.9207 2.98407V2.42853H13.7622C14.2777 2.42853 14.6985 2.82297 14.6985 3.30628V4.92853H1.66153V3.30628ZM1.66153 6.03962H14.6986V13.2174C14.6986 13.7007 14.2778 14.0952 13.7623 14.0952H2.59781C2.08227 14.0952 1.66153 13.7007 1.66153 13.2174V6.03962Z"
        fill="#213F50"
      />
    </svg>
  )
}
