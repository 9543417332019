export const MockMessages = [
  {
    _id: "sdfewef3",
    name: "Agent Smith",
    text: "Hello, Mr.Anderson",
    date: "23-07-29",
    time: "15:30",
    role: "seller",
  },
  {
    _id: "f2efvew",
    name: "Agent Smith",
    text: "Hello, Mr.Anderson",
    date: "23-07-30",
    time: "15:40",
    role: "seller",
  },
  {
    _id: "frerger",
    name: "Agent Smith",
    text: "Hello, Mr.Anderson",
    date: "23-08-02",
    time: "15:30",
    role: "seller",
  },
  {
    _id: "f23f3f",
    name: "Agent Smith",
    text: "Hello, Mr.Anderson Hello, Mr.Anderson Hello, Mr.Anderson Hello, Mr.Anderson \n Hello, Mr.Anderson Hello, Mr.Anderson",
    date: "23-08-02",
    time: "15:40",
    role: "seller",
  },
  {
    _id: "gsdgsdg",
    name: "Agent Smith",
    text: "Hello, Mr.Anderson",
    date: "23-08-03",
    time: "15:50",
    role: "seller",
  },
  {
    _id: "efwefwef",
    name: "Agent Smith",
    text: "Hello, Mr.Anderson",
    date: "23-08-03",
    time: "15:55",
    role: "seller",
  },
  {
    _id: "sdcsdc3",
    name: "Agent Smith",
    text: "Hello, Mr.Anderson",
    date: "23-08-03",
    time: "15:55",
    role: "seller",
  },
  {
    _id: "fewefwefw",
    name: "admin",
    text: "Hello, Smith",
    date: "23-08-03",
    time: "15:56",
    role: "admin",
  },
]
