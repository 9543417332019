export const SearchSVG = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7808 17.6632L12.6621 12.5445C13.6536 11.3198 14.2506 9.76352 14.2506 8.06855C14.2506 4.14008 11.0541 0.943604 7.12564 0.943604C3.19718 0.943604 0.000732422 4.14005 0.000732422 8.06851C0.000732422 11.997 3.19721 15.1935 7.12567 15.1935C8.82065 15.1935 10.3769 14.5965 11.6016 13.605L16.7203 18.7237C16.8666 18.8699 17.0586 18.9434 17.2506 18.9434C17.4426 18.9434 17.6346 18.8699 17.7808 18.7237C18.0741 18.4304 18.0741 17.9564 17.7808 17.6632ZM7.12567 13.6935C4.02369 13.6935 1.50073 11.1705 1.50073 8.06851C1.50073 4.96653 4.02369 2.44357 7.12567 2.44357C10.2277 2.44357 12.7506 4.96653 12.7506 8.06851C12.7506 11.1705 10.2276 13.6935 7.12567 13.6935Z"
        fill="#A7A7A7"
      />
    </svg>
  )
}
