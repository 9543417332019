export const GiftSVG = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9173 15.7643H13.1392V15.3753C13.1413 13.4155 13.9208 11.5366 15.3066 10.1508C16.6924 8.76504 18.5713 7.98556 20.531 7.9834H20.9201V8.76149H20.531C18.7776 8.76345 17.0965 9.46088 15.8565 10.7008C14.6166 11.9407 13.9192 13.6218 13.9173 15.3753V15.7643Z"
        fill="#213F50"
      />
      <path
        d="M9.24864 14.5968H8.47054V14.2077C8.46941 13.2794 8.10015 12.3895 7.44375 11.7331C6.78735 11.0767 5.89741 10.7074 4.96912 10.7063H4.58008V9.92822H4.96912C6.10371 9.92956 7.19144 10.3809 7.99372 11.1831C8.79599 11.9854 9.2473 13.0731 9.24864 14.2077V14.5968Z"
        fill="#213F50"
      />
      <path
        d="M16.6406 14.9864C17.0704 14.9864 17.4187 14.638 17.4187 14.2083C17.4187 13.7785 17.0704 13.4302 16.6406 13.4302C16.2109 13.4302 15.8625 13.7785 15.8625 14.2083C15.8625 14.638 16.2109 14.9864 16.6406 14.9864Z"
        fill="#213F50"
      />
      <path
        d="M17.4187 6.81595C17.8484 6.81595 18.1968 6.46759 18.1968 6.03786C18.1968 5.60813 17.8484 5.25977 17.4187 5.25977C16.989 5.25977 16.6406 5.60813 16.6406 6.03786C16.6406 6.46759 16.989 6.81595 17.4187 6.81595Z"
        fill="#213F50"
      />
      <path
        d="M20.5313 11.8736C20.961 11.8736 21.3094 11.5252 21.3094 11.0955C21.3094 10.6657 20.961 10.3174 20.5313 10.3174C20.1015 10.3174 19.7532 10.6657 19.7532 11.0955C19.7532 11.5252 20.1015 11.8736 20.5313 11.8736Z"
        fill="#213F50"
      />
      <path
        d="M6.13625 14.208C6.56598 14.208 6.91434 13.8597 6.91434 13.4299C6.91434 13.0002 6.56598 12.6519 6.13625 12.6519C5.70652 12.6519 5.35815 13.0002 5.35815 13.4299C5.35815 13.8597 5.70652 14.208 6.13625 14.208Z"
        fill="#213F50"
      />
      <path
        d="M11.1938 17.3203H12.75V24.3232H11.1938V17.3203Z"
        fill="#213F50"
      />
      <path
        d="M13.5283 17.3203H18.5859V24.3232H13.5283V17.3203Z"
        fill="#213F50"
      />
      <path
        d="M5.35815 17.3203H10.4158V24.3232H5.35815V17.3203Z"
        fill="#213F50"
      />
      <path
        d="M21.3092 1.36914L21.9504 2.532L23.2545 2.78255L22.3468 3.75166L22.5114 5.06936L21.3092 4.50524L20.1071 5.06936L20.2717 3.75166L19.364 2.78255L20.6681 2.532L21.3092 1.36914Z"
        fill="#213F50"
      />
      <path
        d="M11.972 8.5249L12.4847 9.45511L13.5281 9.65547L12.8018 10.4308L12.9337 11.4848L11.972 11.0339L11.0102 11.4848L11.1421 10.4308L10.4158 9.65547L11.4592 9.45511L11.972 8.5249Z"
        fill="#213F50"
      />
      <path
        d="M10.7415 2.43773C10.6676 2.01585 10.4427 1.63531 10.1087 1.36718C9.7747 1.09905 9.35453 0.961689 8.92666 0.980752C8.49879 0.999815 8.0925 1.174 7.78369 1.47077C7.47488 1.76753 7.28467 2.16657 7.24861 2.59334L7.15174 3.83168L6.44756 2.80537C6.27402 2.55681 6.03932 2.35717 5.76613 2.22575C5.49294 2.09432 5.19049 2.03553 4.88796 2.05507C4.58543 2.0746 4.29304 2.17179 4.03902 2.33726C3.785 2.50273 3.57793 2.73088 3.43778 2.9997C3.29764 3.26853 3.22916 3.56893 3.23896 3.87194C3.24875 4.17494 3.33649 4.4703 3.49371 4.7295C3.65092 4.98871 3.8723 5.20302 4.13648 5.35173C4.40066 5.50044 4.69871 5.57855 5.00187 5.5785C5.05478 5.5785 5.10769 5.57616 5.16099 5.57111L7.55635 5.33768L9.7494 4.33939C10.1016 4.176 10.39 3.90072 10.5695 3.55642C10.749 3.21211 10.8096 2.81812 10.7419 2.43578L10.7415 2.43773ZM5.08863 4.79652C4.91665 4.8126 4.7435 4.78238 4.58714 4.70898C4.44989 4.64547 4.3291 4.55122 4.23412 4.43354C4.13913 4.31585 4.07251 4.17788 4.03941 4.03032C4.00631 3.88275 4.00762 3.72955 4.04323 3.58257C4.07884 3.43558 4.14781 3.29877 4.24478 3.18272C4.34176 3.06668 4.46414 2.9745 4.60246 2.91334C4.74078 2.85219 4.89131 2.82368 5.04241 2.83004C5.19351 2.8364 5.34112 2.87745 5.47381 2.95002C5.6065 3.02258 5.7207 3.12471 5.80758 3.2485L6.7588 4.63506L5.08863 4.79652ZM9.81165 3.3088C9.71279 3.44951 9.5786 3.56169 9.4226 3.63404L7.89209 4.33083L8.0232 2.65793C8.04152 2.44187 8.13084 2.23794 8.27723 2.07798C8.42361 1.91801 8.61883 1.81099 8.83242 1.77362C8.88997 1.76351 8.94828 1.75843 9.00671 1.75845C9.1868 1.75878 9.36335 1.80847 9.51717 1.90211C9.67099 1.99576 9.7962 2.12978 9.87918 2.28961C9.96216 2.44943 9.99974 2.62895 9.98783 2.80864C9.97593 2.98833 9.91499 3.16132 9.81165 3.3088Z"
        fill="#213F50"
      />
      <path
        d="M4.83577 16.2982L4.6358 15.9648C4.31304 15.4303 3.85807 14.9879 3.31472 14.6802C2.77137 14.3726 2.15794 14.21 1.53354 14.2083H0.689697V13.4302H1.53354C2.29214 13.4323 3.0374 13.6298 3.69755 14.0035C4.35769 14.3773 4.91046 14.9147 5.30262 15.5641L5.50298 15.8979L4.83577 16.2982Z"
        fill="#213F50"
      />
      <path
        d="M18.7597 16.286L18.0637 15.9378L18.2376 15.59C18.6183 14.8221 19.2066 14.1762 19.9357 13.7256C20.6648 13.275 21.5056 13.0377 22.3627 13.0406H23.2544V13.8186H22.3627C21.6506 13.8166 20.9522 14.0141 20.3465 14.3886C19.7409 14.7631 19.2523 15.2998 18.936 15.9378L18.7597 16.286Z"
        fill="#213F50"
      />
      <path
        d="M14.218 3.146L11.2438 3.94315C10.9868 4.42951 10.5765 4.81748 10.0766 5.04688L7.76023 6.10158L5.23532 6.34551C5.15362 6.35291 5.07736 6.35641 5.00189 6.35641C4.52876 6.3586 4.0648 6.22594 3.66435 5.97398L0.689697 6.77113L1.29389 9.02566L14.8222 5.40091L14.218 3.146Z"
        fill="#213F50"
      />
    </svg>
  )
}
