export const CloseSVG = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6938 2.7692C12.2268 -0.697808 6.58377 -0.697808 3.11676 2.7692C1.43732 4.4493 0.512207 6.68257 0.512207 9.05773C0.512207 11.4329 1.43732 13.6662 3.11676 15.3456C4.85061 17.0794 7.12796 17.946 9.40528 17.946C11.6826 17.946 13.96 17.0794 15.6938 15.3456C19.1608 11.8786 19.1608 6.23688 15.6938 2.7692ZM14.7198 14.3715C11.7894 17.3019 7.02117 17.3019 4.09077 14.3715C2.67175 12.9525 1.8899 11.0651 1.8899 9.05773C1.8899 7.05041 2.67175 5.16294 4.09077 3.74322C7.02117 0.812832 11.7894 0.813527 14.7198 3.74322C17.6495 6.67361 17.6495 11.4418 14.7198 14.3715Z"
        fill="#F37171"
      />
      <path
        d="M12.3735 10.9608L10.4233 9.01343L12.3735 7.06605C12.6421 6.79741 12.6421 6.36137 12.3742 6.092C12.1048 5.82197 11.6688 5.82266 11.3994 6.0913L9.44793 8.04007L7.49642 6.0913C7.22708 5.82266 6.79104 5.82197 6.52171 6.092C6.25307 6.36133 6.25307 6.79737 6.5224 7.06605L8.47256 9.01343L6.5224 10.9608C6.25307 11.2294 6.25307 11.6655 6.52171 11.9349C6.65604 12.0699 6.83307 12.1367 7.00943 12.1367C7.18579 12.1367 7.36212 12.0692 7.49645 11.9355L9.44796 9.98675L11.3995 11.9355C11.5338 12.0699 11.7101 12.1367 11.8865 12.1367C12.0629 12.1367 12.2399 12.0692 12.3742 11.9349C12.6428 11.6655 12.6428 11.2294 12.3735 10.9608Z"
        fill="#F37171"
      />
    </svg>
  )
}
