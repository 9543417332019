export const CopySVG = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.12202 3.09204H1.88117C1.20174 3.09204 0.649902 3.64388 0.649902 4.3233V14.7125C0.649902 15.3919 1.20174 15.9438 1.88117 15.9438H9.12202C9.80145 15.9438 10.3533 15.3919 10.3533 14.7125V4.3233C10.3501 3.64388 9.79825 3.09204 9.12202 3.09204ZM9.48884 14.7093C9.48884 14.9134 9.32297 15.0793 9.11883 15.0793H1.87798C1.67383 15.0793 1.50796 14.9134 1.50796 14.7093V4.3233C1.50796 4.11916 1.67383 3.95329 1.87798 3.95329H9.11883C9.32297 3.95329 9.48884 4.11916 9.48884 4.3233V14.7093Z"
        fill="#098341"
      />
      <path
        d="M11.8908 0.36792H4.64997C3.97054 0.36792 3.4187 0.919756 3.4187 1.59918C3.4187 1.83842 3.61009 2.02981 3.84932 2.02981C4.08856 2.02981 4.27995 1.83842 4.27995 1.59918C4.27995 1.39504 4.44582 1.22917 4.64997 1.22917H11.8908C12.095 1.22917 12.2608 1.39504 12.2608 1.59918V11.9884C12.2608 12.1925 12.095 12.3584 11.8908 12.3584C11.6516 12.3584 11.4602 12.5498 11.4602 12.789C11.4602 13.0282 11.6516 13.2196 11.8908 13.2196C12.5702 13.2196 13.1221 12.6678 13.1221 11.9884V1.59918C13.1221 0.919756 12.5702 0.36792 11.8908 0.36792Z"
        fill="#098341"
      />
    </svg>
  )
}
