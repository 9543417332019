import React from "react"

export const BackSVG = () => {
  return (
    <svg
      width="38"
      height="15"
      viewBox="0 0 38 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.41008 6.8055C0.019558 7.19602 0.019558 7.82919 0.41008 8.21971L6.77404 14.5837C7.16457 14.9742 7.79773 14.9742 8.18826 14.5837C8.57878 14.1931 8.57878 13.56 8.18826 13.1695L2.5314 7.5126L8.18826 1.85575C8.57878 1.46523 8.57878 0.83206 8.18826 0.441536C7.79773 0.0510116 7.16457 0.0510116 6.77404 0.441536L0.41008 6.8055ZM37.9365 6.5126L1.11719 6.5126V8.5126L37.9365 8.5126V6.5126Z"
        fill="#213F50"
      />
    </svg>
  )
}
