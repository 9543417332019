import styled from "styled-components"
import { MainContainer } from "./MainContainer"

export const CenterContainer = styled(MainContainer)`
  justify-content: center;
  align-items: center;
`

export const CenterContainerFlex = styled(CenterContainer)`
  flex: 1;
`
