import React from "react"

export const SideLogoSVG = () => {
  return (
    <svg
      width="168"
      height="63"
      viewBox="0 0 168 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.6261 12.982C65.2319 12.982 66.6327 13.2553 67.8285 13.802C69.0243 14.3487 69.9383 15.0918 70.5703 16.0313C71.2024 16.9709 71.5185 18.0215 71.5185 19.1832C71.5185 21.0965 70.9205 22.5998 69.7247 23.6931C68.5289 24.7694 66.496 25.3075 63.6261 25.3075H60.2436V30.2274C60.2436 30.4153 60.1753 30.5776 60.0386 30.7143C59.9019 30.8509 59.7397 30.9193 59.5517 30.9193H54.0424C53.8545 30.9193 53.6922 30.8509 53.5556 30.7143C53.4189 30.5776 53.3506 30.4153 53.3506 30.2274V13.6739C53.3506 13.486 53.4189 13.3237 53.5556 13.187C53.6922 13.0503 53.8545 12.982 54.0424 12.982H63.6261ZM63.5236 20.5669C63.9336 20.5669 64.2581 20.4473 64.4973 20.2082C64.7365 19.9519 64.856 19.6188 64.856 19.2088C64.856 18.7817 64.7365 18.4315 64.4973 18.1582C64.2581 17.8849 63.9336 17.7482 63.5236 17.7482H60.2692V20.5669H63.5236Z"
        fill="#EF52B0"
      />
      <path
        d="M92.3157 23.5137C92.3157 26.1275 91.4444 28.0578 89.7019 29.3049C87.9766 30.552 85.6703 31.1755 82.7833 31.1755C79.8962 31.1755 77.5815 30.552 75.839 29.3049C74.1136 28.0578 73.2509 26.1104 73.2509 23.4625V20.4388C73.2509 18.6963 73.6609 17.2528 74.4809 16.1082C75.318 14.9466 76.454 14.0924 77.889 13.5458C79.324 12.9991 80.9554 12.7258 82.7833 12.7258C84.6112 12.7258 86.2341 12.9991 87.652 13.5458C89.087 14.0924 90.223 14.938 91.0601 16.0826C91.8971 17.2272 92.3157 18.6621 92.3157 20.3875V23.5137ZM80.1696 24.0006C80.1696 24.701 80.4002 25.2391 80.8614 25.615C81.3398 25.9908 81.9804 26.1787 82.7833 26.1787C83.5862 26.1787 84.2183 25.9908 84.6795 25.615C85.1578 25.2221 85.397 24.6754 85.397 23.975V19.9263C85.397 19.2259 85.1578 18.6878 84.6795 18.3119C84.2183 17.919 83.5862 17.7226 82.7833 17.7226C81.9804 17.7226 81.3398 17.9105 80.8614 18.2863C80.4002 18.6621 80.1696 19.2003 80.1696 19.9007V24.0006Z"
        fill="#EF52B0"
      />
      <path
        d="M102.197 24.0006C102.197 24.701 102.427 25.2391 102.889 25.615C103.367 25.9908 104.008 26.1787 104.81 26.1787C105.528 26.1787 106.058 26.0591 106.399 25.82C106.741 25.5808 107.031 25.2221 107.27 24.7437C107.493 24.2996 107.791 24.0775 108.167 24.0775H113.702C113.856 24.0775 113.984 24.1373 114.087 24.2569C114.206 24.3594 114.266 24.4875 114.266 24.6412C114.266 25.6833 113.916 26.7083 113.215 27.7162C112.515 28.707 111.447 29.5355 110.012 30.2018C108.594 30.8509 106.86 31.1755 104.81 31.1755C102.983 31.1755 101.351 30.9022 99.9162 30.3555C98.4983 29.7918 97.3708 28.9376 96.5337 27.7931C95.6966 26.6314 95.2781 25.1879 95.2781 23.4625V20.4388C95.2781 18.7134 95.6966 17.2784 96.5337 16.1338C97.3708 14.9722 98.4983 14.118 99.9162 13.5714C101.351 13.0076 102.983 12.7258 104.81 12.7258C106.86 12.7258 108.594 13.0589 110.012 13.7251C111.447 14.3743 112.515 15.2028 113.215 16.2107C113.916 17.2015 114.266 18.218 114.266 19.2601C114.266 19.4138 114.206 19.5505 114.087 19.67C113.984 19.7725 113.856 19.8238 113.702 19.8238H108.167C107.791 19.8238 107.493 19.6017 107.27 19.1576C107.031 18.6792 106.741 18.3205 106.399 18.0813C106.058 17.8422 105.528 17.7226 104.81 17.7226C104.008 17.7226 103.367 17.9105 102.889 18.2863C102.427 18.6621 102.197 19.2003 102.197 19.9007V24.0006Z"
        fill="#EF52B0"
      />
      <path
        d="M135.166 12.982C135.354 12.982 135.516 13.0503 135.653 13.187C135.789 13.3237 135.858 13.486 135.858 13.6739V17.7994C135.858 17.9874 135.789 18.1497 135.653 18.2863C135.516 18.423 135.354 18.4913 135.166 18.4913H129.656V30.2274C129.656 30.4153 129.588 30.5776 129.451 30.7143C129.315 30.8509 129.152 30.9193 128.965 30.9193H123.686C123.498 30.9193 123.336 30.8509 123.199 30.7143C123.062 30.5776 122.994 30.4153 122.994 30.2274V18.4913H117.485C117.297 18.4913 117.134 18.423 116.998 18.2863C116.861 18.1497 116.793 17.9874 116.793 17.7994V13.6739C116.793 13.486 116.861 13.3237 116.998 13.187C117.134 13.0503 117.297 12.982 117.485 12.982H135.166Z"
        fill="#EF52B0"
      />
      <path
        d="M157.628 23.5137C157.628 26.1275 156.757 28.0578 155.015 29.3049C153.289 30.552 150.983 31.1755 148.096 31.1755C145.209 31.1755 142.894 30.552 141.152 29.3049C139.426 28.0578 138.564 26.1104 138.564 23.4625V20.4388C138.564 18.6963 138.974 17.2528 139.794 16.1082C140.631 14.9466 141.767 14.0924 143.202 13.5458C144.637 12.9991 146.268 12.7258 148.096 12.7258C149.924 12.7258 151.547 12.9991 152.965 13.5458C154.4 14.0924 155.536 14.938 156.373 16.0826C157.21 17.2272 157.628 18.6621 157.628 20.3875V23.5137ZM145.482 24.0006C145.482 24.701 145.713 25.2391 146.174 25.615C146.653 25.9908 147.293 26.1787 148.096 26.1787C148.899 26.1787 149.531 25.9908 149.992 25.615C150.471 25.2221 150.71 24.6754 150.71 23.975V19.9263C150.71 19.2259 150.471 18.6878 149.992 18.3119C149.531 17.919 148.899 17.7226 148.096 17.7226C147.293 17.7226 146.653 17.9105 146.174 18.2863C145.713 18.6621 145.482 19.2003 145.482 19.9007V24.0006Z"
        fill="#EF52B0"
      />
      <path
        d="M70.5447 52.2461C70.5447 54.8598 69.6735 56.7902 67.931 58.0373C66.2056 59.2843 63.8994 59.9079 61.0123 59.9079C58.1253 59.9079 55.8105 59.2843 54.0681 58.0373C52.3427 56.7902 51.48 54.8427 51.48 52.1948V49.1711C51.48 47.4287 51.89 45.9851 52.71 44.8406C53.547 43.6789 54.6831 42.8248 56.118 42.2781C57.553 41.7314 59.1845 41.4581 61.0123 41.4581C62.8402 41.4581 64.4631 41.7314 65.881 42.2781C67.316 42.8248 68.452 43.6704 69.2891 44.8149C70.1262 45.9595 70.5447 47.3945 70.5447 49.1199V52.2461ZM58.3986 52.733C58.3986 53.4334 58.6293 53.9715 59.0905 54.3473C59.5688 54.7231 60.2094 54.9111 61.0123 54.9111C61.8153 54.9111 62.4473 54.7231 62.9086 54.3473C63.3869 53.9544 63.6261 53.4077 63.6261 52.7073V48.6586C63.6261 47.9582 63.3869 47.4201 62.9086 47.0443C62.4473 46.6514 61.8153 46.4549 61.0123 46.4549C60.2094 46.4549 59.5688 46.6428 59.0905 47.0187C58.6293 47.3945 58.3986 47.9326 58.3986 48.633V52.733Z"
        fill="#EF52B0"
      />
      <path
        d="M89.9838 41.7144C90.1717 41.7144 90.334 41.7827 90.4707 41.9194C90.6073 42.056 90.6757 42.2183 90.6757 42.4062V54.1423H91.9825C92.1705 54.1423 92.3327 54.2107 92.4694 54.3473C92.6061 54.484 92.6744 54.6463 92.6744 54.8342V62.1372C92.6744 62.3251 92.6061 62.4874 92.4694 62.6241C92.3327 62.7607 92.1705 62.8291 91.9825 62.8291H87.8313C87.6434 62.8291 87.4811 62.7607 87.3445 62.6241C87.2078 62.4874 87.1395 62.3251 87.1395 62.1372V59.6516H78.4015V62.1372C78.4015 62.3251 78.3331 62.4874 78.1965 62.6241C78.0598 62.7607 77.8975 62.8291 77.7096 62.8291H73.584C73.3961 62.8291 73.2338 62.7607 73.0972 62.6241C72.9605 62.5045 72.8922 62.3422 72.8922 62.1372V54.8342C72.8922 54.6463 72.9605 54.484 73.0972 54.3473C73.2338 54.2107 73.3961 54.1423 73.584 54.1423H73.994C74.5578 54.1423 74.9934 53.6554 75.3009 52.6817C75.6084 51.708 75.7621 49.9228 75.7621 47.3262V42.4062C75.7621 42.2183 75.8305 42.056 75.9671 41.9194C76.1038 41.7827 76.2661 41.7144 76.454 41.7144H89.9838ZM81.9121 47.8387C81.9121 49.2395 81.8352 50.4438 81.6814 51.4517C81.5277 52.4425 81.2714 53.3394 80.9127 54.1423H84.5258V46.9674H81.9121V47.8387Z"
        fill="#EF52B0"
      />
      <path
        d="M100.864 58.7804C100.642 59.3612 100.258 59.6516 99.7112 59.6516H94.8681C94.7144 59.6516 94.5777 59.6004 94.4581 59.4979C94.3556 59.3783 94.3044 59.2416 94.3044 59.0879L94.33 58.9341L99.9162 42.56C99.9845 42.3379 100.113 42.1414 100.301 41.9706C100.488 41.7998 100.745 41.7144 101.069 41.7144H108.193C108.518 41.7144 108.774 41.7998 108.962 41.9706C109.15 42.1414 109.278 42.3379 109.346 42.56L114.932 58.9341L114.958 59.0879C114.958 59.2416 114.898 59.3783 114.778 59.4979C114.676 59.6004 114.548 59.6516 114.394 59.6516H109.551C109.004 59.6516 108.62 59.3612 108.398 58.7804L107.757 56.9867H101.505L100.864 58.7804ZM104.631 46.6599L102.812 51.9898H106.45L104.631 46.6599Z"
        fill="#EF52B0"
      />
      <path
        d="M128.939 41.7144C130.545 41.7144 131.946 41.9877 133.141 42.5343C134.337 43.081 135.251 43.8241 135.883 44.7637C136.515 45.7033 136.831 46.7539 136.831 47.9155C136.831 49.8288 136.233 51.3321 135.038 52.4255C133.842 53.5017 131.809 54.0398 128.939 54.0398H125.556V58.9597C125.556 59.1477 125.488 59.31 125.351 59.4466C125.215 59.5833 125.052 59.6516 124.865 59.6516H119.355C119.167 59.6516 119.005 59.5833 118.868 59.4466C118.732 59.31 118.663 59.1477 118.663 58.9597V42.4062C118.663 42.2183 118.732 42.056 118.868 41.9194C119.005 41.7827 119.167 41.7144 119.355 41.7144H128.939ZM128.836 49.2993C129.246 49.2993 129.571 49.1797 129.81 48.9405C130.049 48.6843 130.169 48.3511 130.169 47.9412C130.169 47.5141 130.049 47.1639 129.81 46.8905C129.571 46.6172 129.246 46.4805 128.836 46.4805H125.582V49.2993H128.836Z"
        fill="#EF52B0"
      />
      <path
        d="M144.688 41.7144C144.876 41.7144 145.038 41.7827 145.175 41.9194C145.312 42.056 145.38 42.2183 145.38 42.4062V50.7086L150.966 42.2269C151.222 41.8852 151.555 41.7144 151.965 41.7144H156.117C156.305 41.7144 156.467 41.7827 156.603 41.9194C156.74 42.056 156.808 42.2183 156.808 42.4062V58.9597C156.808 59.1477 156.74 59.31 156.603 59.4466C156.467 59.5833 156.305 59.6516 156.117 59.6516H151.35C151.163 59.6516 151 59.5833 150.864 59.4466C150.727 59.31 150.659 59.1477 150.659 58.9597V51.2211L145.072 59.1135C144.799 59.4722 144.457 59.6516 144.047 59.6516H139.922C139.734 59.6516 139.572 59.5833 139.435 59.4466C139.298 59.31 139.23 59.1477 139.23 58.9597V42.4062C139.23 42.2183 139.298 42.056 139.435 41.9194C139.572 41.7827 139.734 41.7144 139.922 41.7144H144.688Z"
        fill="#EF52B0"
      />
      <path
        d="M45.9322 12.5112C46.4261 12.5112 46.8526 12.6908 47.2117 13.05C47.5709 13.4091 47.7505 13.8357 47.7505 14.3295V57.8333C47.7505 58.3272 47.5709 58.7537 47.2117 59.1129C46.8526 59.472 46.4261 59.6516 45.9322 59.6516H32.7329C32.2391 59.6516 31.8126 59.472 31.4534 59.1129C31.0942 58.7537 30.9146 58.3272 30.9146 57.8333V27.0574H17.446V57.8333C17.446 58.3272 17.2664 58.7537 16.9072 59.1129C16.548 59.472 16.1215 59.6516 15.6277 59.6516H2.42838C1.93453 59.6516 1.50802 59.4945 1.14886 59.1802C0.78969 58.821 0.610107 58.3721 0.610107 57.8333V14.3295C0.610107 13.8357 0.78969 13.4091 1.14886 13.05C1.50802 12.6908 1.93453 12.5112 2.42838 12.5112H45.9322Z"
        fill="#EF52B0"
      />
      <path
        d="M167.491 8.61398C167.491 11.5003 165.152 13.84 162.265 13.84C159.379 13.84 157.039 11.5003 157.039 8.61398C157.039 5.72771 159.379 3.38792 162.265 3.38792C165.152 3.38792 167.491 5.72771 167.491 8.61398Z"
        fill="#EF52B0"
      />
      <path
        d="M41.2636 10.9707C41.8156 8.73946 42.4875 4.30892 40.9641 2.15555C40.6642 1.74574 40.2703 1.41396 39.8154 1.18811C39.3606 0.96226 38.8582 0.84896 38.3505 0.857741C35.6301 0.728958 32.2777 2.9003 28.8665 7.01137C29.9763 8.06422 30.7005 9.45919 30.923 10.9727L41.2636 10.9707Z"
        fill="#EF52B0"
      />
      <path
        d="M17.4106 10.9707C17.6332 9.45698 18.3578 8.06194 19.4681 7.00933C16.0568 2.89826 12.7125 0.729917 9.98408 0.855705C9.47615 0.846596 8.97348 0.959746 8.51844 1.18561C8.0634 1.41148 7.66934 1.74345 7.36949 2.15352C5.84605 4.31288 6.51992 8.74241 7.06999 10.9667L17.4106 10.9707Z"
        fill="#EF52B0"
      />
      <path
        d="M20.0351 10.9706H28.9072C28.6526 9.75091 27.9374 8.67637 26.9105 7.97068C26.1019 7.42454 25.1484 7.13272 24.1726 7.13272C23.1969 7.13272 22.2434 7.42454 21.4348 7.97068C20.4054 8.67597 19.688 9.75135 19.4321 10.9726L20.0351 10.9706Z"
        fill="#EF52B0"
      />
      <path
        d="M24.1699 17.8171L20.943 12.228L27.3967 12.228L24.1699 17.8171Z"
        fill="#FFF0F9"
      />
    </svg>
  )
}
